import React from 'react';
import {
  Grid,
  GridProps,
  GridItem,
  GridItemProps,
  Text,
  TextProps,
  useMultiStyleConfig,
  Skeleton,
  Flex,
} from '@newday/core';

interface Item {
  label: string;
  content?: string;
  testId?: string;
}

export interface BreakdownProps extends Omit<GridProps, 'css'> {
  variant?: 'default' | 'light' | 'dark';
  itemsPerRow?: 1 | 2;
  items: Item[];
  layout?: 'singleColumn' | 'singleRow';
  dynamicPricing?: boolean;
}

export const Breakdown: React.FC<BreakdownProps> = ({
  items,
  itemsPerRow = 2,
  layout,
  dynamicPricing = false,
  ...props
}) => {
  const styles = useMultiStyleConfig('Breakdown', props);
  const containerStyles = styles.container as Partial<GridProps>;
  const itemStyles = styles.item as Partial<GridItemProps>;
  const labelStyles = styles.label as Partial<TextProps>;
  const contentStyles = styles.content as Partial<TextProps>;

  const singleColumnLayout = layout === 'singleColumn';
  const singleRowLayout = layout === 'singleRow';

  /* istanbul ignore next */
  if (singleColumnLayout) {
    itemsPerRow = 1;
  }

  /* istanbul ignore next */
  if (singleRowLayout) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    itemsPerRow = items.length as any;
  }

  const gridTemplateColumns = new Array(itemsPerRow).fill('1fr').join(' ');

  return (
    <dl>
      <Grid
        {...props}
        sx={containerStyles}
        templateColumns={gridTemplateColumns}
        height={
          /* istanbul ignore next */ singleColumnLayout ? 'full' : 'initial'
        }
      >
        {items.map(({ label, content, testId }, index) => (
          <GridItem
            key={`${label}-${content}-${index}`}
            sx={itemStyles}
            colSpan={
              dynamicPricing
                ? 1
                : (itemsPerRow === 1 || index === items.length - 1) &&
                    !singleRowLayout
                  ? itemsPerRow
                  : 1
            }
            border="1px"
            textAlign={
              /* istanbul ignore next */ singleColumnLayout ? 'center' : 'left'
            }
          >
            <Flex
              height="full"
              flexDirection="column"
              justifyContent={
                /* istanbul ignore next */ singleColumnLayout
                  ? 'center'
                  : 'start'
              }
            >
              <>
                <Text data-testid={`${testId}-label`} sx={labelStyles} as="dt">
                  {label}
                </Text>
                {content ? (
                  <Text data-testid={`${testId}-content`} sx={contentStyles} as="dd">
                    {content}
                  </Text>
                ) : (
                  <Skeleton data-testid="loading">
                    <Text sx={contentStyles} as="dd">
                      Loading
                    </Text>
                  </Skeleton>
                )}
              </>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </dl>
  );
};
